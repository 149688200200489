import { useTrackLoggedIn } from '@air/analytics';
import { IDP_ACCOUNT_EXISTS } from '@air/errors';
import { useToasts } from '@air/provider-toast';
import { getCurrentSessionKey, getCurrentUserInfoKey, loginViaEmail, useGetCurrentAccount } from '@air/utils-auth';
import { convertUnknownToError, reportErrorToBugsnag } from '@air/utils-error';
import { useQueryClient } from '@tanstack/react-query';
import { isString } from 'lodash';

import { ExistingAccountMessageWithAuthLink } from '~/utils/Auth';
import { sanitizeEmail } from '~/utils/EmailUtils';

const getSubmitErrorMessage = (error: unknown, email: string) => {
  const _error = convertUnknownToError(error);

  if (_error.message.includes(IDP_ACCOUNT_EXISTS.message)) {
    return (
      <ExistingAccountMessageWithAuthLink
        rawErrorMessage={_error.message}
        messagePrefix="No password on this account."
      />
    );
  }

  if (_error.message.includes('NotAuthorizedException')) {
    return 'Incorrect username or password.';
  }

  if (_error.message.includes('UserNotFoundException')) {
    return 'User does not exist.';
  }

  reportErrorToBugsnag({
    error,
    context: 'Failed to login',
    metadata: {
      Data: { emailFromAttemptedLogin: email },
    },
  });

  return _error.message;
};

export const useLogin = () => {
  const { showToast } = useToasts();
  const { getCurrentAccount } = useGetCurrentAccount();
  const { trackLoggedIn } = useTrackLoggedIn();
  const queryClient = useQueryClient();

  const loginUser = async (username: string, password: string) => {
    await loginViaEmail({ username: sanitizeEmail(username), password });

    queryClient.invalidateQueries({ queryKey: getCurrentUserInfoKey() });
    queryClient.invalidateQueries({ queryKey: getCurrentSessionKey() });

    const account = await getCurrentAccount();
    const { id, email, firstName, lastName } = account;
    trackLoggedIn({ id, email, firstName, lastName, authProvider: 'Cognito' });
    return account;
  };

  const showLoginError = (username: string, error: unknown) => {
    const errorMessage = getSubmitErrorMessage(error, username);
    showToast(errorMessage, {
      timeBeforeAutoDismiss: isString(errorMessage) ? undefined : null,
      type: 'assertive',
      withCloseButton: true,
    });
  };

  return {
    loginUser,
    showLoginError,
  };
};
